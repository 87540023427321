<template>
  <v-form class="form" ref="form" v-model="valid" v-on:submit.prevent="save">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-card-title class="formTitle">Change password</v-card-title>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-text-field v-model="formData.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      :rules="[rules.required, rules.password_length, rules.password_letter, rules.password_number]"
                      @click:append="showPassword = !showPassword" label="Password"
                      required></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-text-field v-model="formData.password_confirm" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      ref="password_confirm"
                      :rules="[rules.required, rules.match]" @click:append="showPassword = !showPassword"
                      label="Confirm password" required></v-text-field>
      </v-col>
      <v-col cols="12" md="6" class="formContent">
        <v-btn class="connectBtn" @click="save">Save</v-btn>
      </v-col>
    </v-col>
  </v-form>
</template>

<script>


export default {
  name: "ChangePasswordFirstLog",
  data() {
    return {
      valid: true,
      loading: false,
      ui: {
        alert: {
          enable: false,
          dismissible: false,
          icon: 'mdi-check',
          type: 'info',
          message: 'Test'
        }
      },
      formData: {
        password: '',
        password_confirm: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        match: value => (!!value && value) === this.formData.password || 'Passwords do not match',
        password_length: value => value.length >= 8 || 'Password should be at least 8 characters',
        password_letter: value => {
          const pattern = /\d/
          return pattern.test(value) || 'Must contain at least a number'
        },
        password_number: value => {
          const pattern = /[a-zA-Z]/
          return pattern.test(value) || 'Must contain at least one letter'
        }
      },
      showPassword: false,
    }
  },
  watch: {
    "formData.password": function () {
      this.$refs.password_confirm.validate()
    }
  },
  methods: {
    alert(type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    /**
     * Change user password && redirect to home
     *
     * @returns {Promise<void>}
     */
    async save() {
      if (this.$refs.form.validate()) {

        this.loading = true

        try {
          const response = (await this.axios.post('user/first_password', {
            auth_token: this.$cookies.get('tmp_token'),
            password: this.formData.password,
            confirmation_password: this.formData.password_confirm
          })).data

          this.$session.start()
          this.$session.set('token', response.auth_token)
          this.$session.set('key', response.user.key)
          await this.$router.push({name: 'Home'})
          this.$cookies.remove('tmp_token')


        } catch (e) {
          if (e.response?.status !== undefined) {
            switch (e.response.status) {

                // Invalid params
              case 400:
                this.alert('error', 'mdi-alert-circle-outline', 'Les paramètres sont invalides. Veuillez réessayer.', true)
                break

                // User was not found
              case 401:
                this.alert('error', 'mdi-alert-circle-outline', 'Your temporary login authorization has expired. Please contact support.', true)
                break

                // Password format invalid
              case 403:
                this.alert('error', 'mdi-alert-circle-outline', 'Password must be minimum 8 characters and contain at least a number and a letter.', true)
                break

                // Passwords do not match
              case 406:
                this.alert('error', 'mdi-alert-circle-outline', 'Passwords do not match.', true)
                break

                // Invalid method
              case 405:
                this.alert('error', 'mdi-alert-circle-outline', 'Une erreur s\'est produite. Veuillez réessayer.', true)
                break

              default:
                this.alert('error', 'mdi-alert-circle-outline', 'Une erreur s\'est produite. Veuillez réessayer.', true)
                break
            }
          }
        }
      }
    },
    /**
     * Keyboard enter action
     */
    keyboard_enter_action: function (e) {
      if (e.keyCode === 13) {
        this.save()
      }
    },
    /**
     * Listen keyboard enter request
     */
    listen_keyboard_enter_request: function () {
      document.addEventListener("keydown", this.keyboard_enter_action, false);
    },
    /**
     * Stop listen keyboard enter request
     */
    stop_listen_keyboard_enter_request: function () {
      document.removeEventListener("keydown", this.keyboard_enter_action, false);
    }
  },
  mounted() {
    this.listen_keyboard_enter_request();
  },
  beforeDestroy() {
    this.stop_listen_keyboard_enter_request();
  }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-overlay__scrim {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
  }
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .formContent {
    margin: 0 auto;

    .formTitle {
      padding: 0;
      color: dimgray;
      font-size: 2rem;
      font-weight: bold;
    }

    .forgotten {
      font-size: 80%;
      display: flex;
      justify-content: right;
      color: RGBA(121, 7, 72, 1);
    }

    .v-progress-circular {
      margin: 1rem;
      color: RGBA(121, 7, 72, 1);
    }

    .connectBtn {
      width: 100%;
      padding: 0;
      color: #ffffff;
      font-size: 0.7rem;
      background-color: RGBA(121, 7, 72, 1);
    }

    .newAccount {
      padding: 2% 0 0 0;

      .account {
        color: RGBA(121, 7, 72, 1);

      }
    }

    .account {
      color: RGBA(121, 7, 72, 1);

    }
  }

}
</style>
